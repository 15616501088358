import { FormButton } from "@app/components/Form/FormButton"
import { Link } from "@app/components/Link"
import { withSection } from "@app/hoc/Section"
import { useCore } from "@app/hooks/useCore"
import { useImage } from "@app/hooks/useImage"
import { useMedia } from "@app/hooks/useMedia"
import { useRoutes } from "@app/hooks/useRoutes"
import { useAppContext } from "@app/providers/app"
import { AspectRatio, Box, Image, VStack, Heading } from "@chakra-ui/react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import type { ScrollPositionProps, Section } from "@root/types/global"
import React, { memo, useEffect, useMemo, useState } from "react"
import ReactPlayer from "react-player/lazy"

type Props = Section & GatsbyTypes.SanitySectionHeroBanner

const HeroBanner: React.FC<Props> = withSection(
  memo(props => {
    const {
      title: rawTitle,
      subtitle: rawSubtitle,
      links: rawLinks,
      linkStyle,
      themeColour,
      headerBackground = "white",
      image: rawImage,
      video: rawVideo,
      horizontalAlignment,
      verticalAlignment,
      isHomepage,
      index,
    } = props
    const {
      helpers: { linebreak },
    } = useCore()
    const { getGatsbyImage, getResponsiveVideo } = useImage()
    const { isBase } = useMedia()
    const { urlResolver } = useRoutes()
    const { dispatch, state, activeHeaderHeight } = useAppContext()
    const [position, setPosition] = useState<number>(0)

    const title = useMemo(() => linebreak(rawTitle), [linebreak, rawTitle])
    const subtitle = useMemo(() => linebreak(rawSubtitle), [linebreak, rawSubtitle])
    const links = useMemo(() => rawLinks?.map(link => urlResolver(link)), [rawLinks, urlResolver])

    const image = useMemo(() => getGatsbyImage(rawImage, { width: isBase ? 500 : 1000 }), [getGatsbyImage, isBase, rawImage])
    const imageDesktop = getGatsbyImage(image?.desktop)
    const imageMobile = getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, { width: 1000 })

    const video = getResponsiveVideo(rawVideo, {
      breakpoint: isHomepage ? "isMedium" : "isSmall",
      desktop: { width: 2000 },
      mobile: { width: 1000 },
    })

    const background = video
      ? "transparent"
      : image
      ? "transparent"
      : themeColour?.themeColourBackground?.theme === "custom"
      ? themeColour?.themeColourBackground?.custom?.hex
      : themeColour?.themeColourBackground?.theme
    const textColor =
      themeColour?.themeColourText?.theme === "custom" ? themeColour?.themeColourText?.custom?.hex : themeColour?.themeColourText?.theme

    useScrollPosition(({ currPos }: ScrollPositionProps) => setPosition(currPos?.y), [], undefined, true, 100)

    useEffect(() => {
      const activeInversion = headerBackground === "transparent" ? true : false

      if ((state.activeInversion !== activeInversion || state.activeBackground !== headerBackground) && !index && isHomepage)
        dispatch({
          type: "theme",
          payload: {
            activeBackground: headerBackground,
            activeInversion,
          },
        })
    }, [background, dispatch, isHomepage, index, position, state, headerBackground])

    return (
      <Box
        textAlign={"start"}
        as="section"
        id="hero-banner-section"
        position="relative"
        display="flex"
        mt={headerBackground === "transparent" && index === 0 && activeHeaderHeight ? `-${activeHeaderHeight}px` : 0}
        bg={background}
      >
        <AspectRatio ratio={{ base: image || video ? 0.5 : 1, sm: image || video ? 0.85 : 1, md: 2 }} w="full">
          {video ? (
            <Box
              pos="absolute"
              inset={0}
              h="full"
              w="full"
              pointerEvents="none"
              sx={{ "> div, > div > video": { h: "100% !important", objectFit: "cover", objectPosition: "center" } }}
            >
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      poster: video.poster?.src,
                    },
                  },
                }}
                height="auto"
                width="100%"
                loop
                muted
                playing
                playsinline
                url={video.src}
                volume={0}
              />
            </Box>
          ) : (
            <>
              {imageDesktop && (
                <Image
                  {...imageDesktop}
                  alt={imageDesktop?.alt || title || ""}
                  display={{ base: "none !important", sm: isHomepage ? "flex !important" : "none !important", md: "flex !important" }}
                  maxWidth={{ base: "100%", lg: "100%" }}
                  maxHeight={{ base: "100%", lg: "100%" }}
                  objectFit="cover"
                  objectPosition="center"
                />
              )}
              {imageMobile && (
                <Image
                  {...imageMobile}
                  alt={imageMobile?.alt || title || ""}
                  display={{ base: "flex !important", sm: isHomepage ? "none !important" : "flex !important", md: "none !important" }}
                  maxWidth={{ base: "100%", lg: "100%" }}
                  maxHeight={{ base: "100%", lg: "100%" }}
                  objectFit="cover"
                  objectPosition="center"
                />
              )}
            </>
          )}
        </AspectRatio>
        <VStack
          position="absolute"
          inset={0}
          justifyContent={{
            base: verticalAlignment?.mobile === "top" ? "start" : verticalAlignment?.mobile === "middle" ? "center" : "end",
            md: verticalAlignment?.desktop === "top" ? "start" : verticalAlignment?.desktop === "middle" ? "center" : "end",
          }}
          alignItems={{
            base: horizontalAlignment?.mobile === "left" ? "start" : horizontalAlignment?.mobile === "centre" ? "center" : "end",
            md: horizontalAlignment?.desktop === "left" ? "start" : horizontalAlignment?.desktop === "centre" ? "center" : "end",
          }}
          color={textColor}
          p={{
            base: index === 0 ? `112px 24px` : `48px 24px`,
            md: "112px",
          }}
          spacing={{ base: 6, md: 4 }}
          textAlign={{ base: "center", md: "start" }}
        >
          {title && (
            <Heading as="p" size="mainHeading" fontSize={32} maxW={360} color={textColor}>
              {title}
            </Heading>
          )}
          {subtitle && <Heading as="p" size="subHeading" dangerouslySetInnerHTML={{ __html: subtitle }} color={textColor} />}
          <Box display={{ base: "block", sm: "flex" }} gap={6}>
            {links.slice(0, 2).map((link, index) =>
              linkStyle === "button" ? (
                <FormButton
                  key={index}
                  as={Link}
                  to={link?.url}
                  textAlign={"center"}
                  size={"md"}
                  variant="secondary"
                  fontSize={14}
                  lineHeight={"16px"}
                  fontWeight={700}
                  letterSpacing={2.8}
                  textColor={textColor === "white" ? "#615D59" : "white"}
                  minW={{ base: "165px", md: "160px" }}
                  minH="49px"
                  mt={{ base: index !== 0 ? 6 : 2, sm: 2, md: 0 }}
                >
                  {link.title}
                </FormButton>
              ) : (
                <Link
                  key={index}
                  to={link.url}
                  size="underline"
                  variant="underline"
                  title={link.title}
                  aria-label={link.title}
                  textColor={textColor ? textColor : "white"}
                  borderColor={textColor ? textColor : "white"}
                  borderBottom="2px"
                  lineHeight={"2.8px"}
                  fontWeight={700}
                  letterSpacing={2.8}
                  fontSize={{ base: 14 }}
                  textAlign={"center"}
                  minW={{ base: "161px" }}
                  py={2}
                  px={0}
                >
                  {link.title}
                </Link>
              )
            )}
          </Box>
        </VStack>
      </Box>
    )
  })
)

export default HeroBanner
