import React from "react"
import { InView } from "react-intersection-observer"

import { useAnalytics } from "@app/hooks/useAnalytics"

import type { Section } from "@root/types/global"

export const withSection =
  (Component: React.FC<any>) =>
  ({ analytics, name, ...props }: Section) => {
    const { trackPromoImpression, trackPromoClick } = useAnalytics()

    Component.displayName = name
    return (
      <InView onChange={(visible: boolean) => visible && trackPromoImpression({ ...analytics })}>
        <Component {...props} handleTrackingClick={() => trackPromoClick({ ...analytics })} />
      </InView>
    )
  }
